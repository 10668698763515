.inputContainer{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.input{
  width: calc(100% - 40px);
  flex-shrink: 0;
}

.select{
  margin-inline-start: 2px;
  flex-shrink: 0;
  width: 38px;
}
