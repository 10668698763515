/*================================*/
/* Global */
/*================================*/
/* TODO : find a way to make font-family as a Golbal Variable  */
:root {
  --fc-border-color: #f4f4f5;
  --fc-daygrid-event-dot-width: 5px;
  --calendarBorder: var(--gray-200);
  font-family: 'Tajawal';
  --fc-today-bg-color: var(--gray-200);
}

.t_dark {
  --fc-daygrid-event-dot-width: 5px;
  --fc-border-color: var(--gray-800);
  --calendarBorder: var(--gray-800);
  font-family: 'Tajawal';
  --fc-today-bg-color: var(--gray-800);
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  padding: 1em 0em 2em;
  background: --background;
}

.fc.fc-media-screen.fc-direction-rtl.fc-theme-standard {
  padding: 1em 2em 0;
  border-radius: 10px;
}

.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past,
.fc-daygrid-dot-event {
  background: rgba(221, 19, 19, 0);
  border-radius: 1em;
}

.fc-direction-ltr .fc-daygrid-week-number {
  border-radius: 0px 5px 5px 0px;
  left: 0px;
  color: black;
  padding: 5px;
}

.fc .fc-timegrid-now-indicator-line {
  border-color: var(--brand);
  border-width: 2px 0px 0px;
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: var(--brand);
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-width: 5px 0px 5px 6px;
  left: 0px;
}

/*================================*/
/* Week */
/*================================*/
.fc-view-harness.fc-view-harness-active {
  background-color: --background;
  border-radius: 1em;
}

.fc-daygrid-day-events {
  width: 100%;
}

/*================================*/
/* Month */
/*================================*/

.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
  margin: 0;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: var(--brand);
  color: white;
  padding: 8px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  padding: 0;
  margin: 0;
  display: contents;
}

.fc-col-header {
  padding: 1em;
}

.fc .fc-scrollgrid-liquid {
  border: 1px solid var(--calendarBorder);
  border-radius: 6px;
}

.fc-scrollgrid-sync-inner {
  margin: 12px 0;
}

.css-view-175oi2r.r-backgroundColor-t7qch7.r-borderRadius-kdyh1x.r-marginBottom-6gpygo {
  margin: 0.1em;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--calendarBorder);
}

.fc .fc-daygrid-day-number {
  background-color: #ffffff00;
  border-radius: 50%;
  padding: 11px;
  min-width: 33px;
  min-height: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin: 0 4px;
}

.fc-day.fc-day-thu.fc-day-today.fc-daygrid-day {
  background-color: white;
}

.t_dark .fc-day.fc-day-thu.fc-day-today.fc-daygrid-day {
  background-color: #18191a;
}

.fc-day.fc-day-thu.fc-day-today.fc-daygrid-day .fc-daygrid-day-number {
  color: white;
  font-size: 12px;
}

.t_dark .fc-day.fc-day-thu.fc-day-today.fc-daygrid-day .fc-daygrid-day-number {
  color: #18191a;
  font-size: 12px;
}

.fc-day.fc-day-past.fc-day-other.fc-daygrid-day {
  background-color: var(--gray-200);
}

.t_dark .fc-day.fc-day-past.fc-day-other.fc-daygrid-day {
  background-color: var(--gray-800);
}

/*================================*/
/* day */
/*================================*/

.fc .fc-timegrid-slot-minor {
  border-top-width: 0px;
}

th.fc-col-header-cell.fc-day.fc-day-tue.fc-day-today {
  color: var(--brand);
}

@media print {
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: 100vh !important;
  }
}
